import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { updateBreadcrumbs } from 'src/redux/actions/breadcrumbs';
import AppCard from 'src/components/Elements/AppCard';

const NetworkHome = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateBreadcrumbs({ key: 'home' }));
  }, []);

  const clientServices = [{
    name: 'Client Manager',
    url: '/network/clients'
  }, {
    name: 'Current Resources',
    url: '/network/current-resources'
  }, {
    name: 'Invoicing',
    url: '/network/invoices'
  }, {
    name: 'Email Administrators',
    url: '/network/email-administrators',
  }, {
    name: 'System Notifications',
    url: '/network/system-notifications',
  }, {
    name: 'System Logging',
    url: '/network/system-logs'
  }];

  const userServices = [{
    name: 'View User',
    url: '/network/view-user'
  }, {
    name: 'User Migration',
    url: '/network/migrate-user'
  }, {
    name: 'Email Change',
    url: '/network/change-email'
  }, {
    name: 'Password Change',
    url: '/network/change-password'
  }, {
    name: 'Password Change Requests',
    url: '/network/password-requests'
  }];

  return (
    <section className="app-grid home-page">
      <div className="app-grid-row">
        <AppCard
          className="flex-2"
          footerLink={{
            label: `https://cpjam.com`,
            url: `https://cpjam.com`
          }}
          title="Client Portal"
        >
          <div className="app-grid-title">
            <img className="logo" src="/assets/images/logo.svg" alt="Logo" />
          </div>
        </AppCard>
        <AppCard
          className="flex-1"
          title="Client Services"
        >
          <div className="app-grid-list">
            { clientServices.map(service => {
              return (
                <Link
                  className="item"
                  key={service.url}
                  to={service.url}
                >
                  {service.name}
                </Link>
              )
            }) }
          </div>
        </AppCard>
        <AppCard
          className="flex-1"
          title="User Services"
        >
          <div className="app-grid-list">
            { userServices.map(service => {
              return (
                <Link
                  className="item"
                  key={service.url}
                  to={service.url}
                >
                  {service.name}
                </Link>
              )
            }) }
          </div>
        </AppCard>
        </div>
    </section>
  )
}

export default NetworkHome;
